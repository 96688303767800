/* Base styles */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: linear-gradient(135deg, #0f1424 0%, #070a14 100%);
  color: white;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header */
.App-header {
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
}

/* Main container */
.main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Logo */
.logo-container {
  margin-bottom: 40px;
  text-align: center;
}

.App-logo {
  height: 180px;
  margin-bottom: 16px;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(0, 102, 255, 0.2);
}

.tagline {
  color: #8a94a6;
  margin-top: 0;
}

/* Staking container */
.staking-container {
  background: rgba(17, 25, 40, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
}

/* Tabs */
.tabs {
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.tab-btn {
  flex: 1;
  background: transparent;
  border: none;
  color: #8a94a6;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
  border-radius: 0; /* Override the global button border-radius */
}

.tab-btn.active {
  color: #ffffff;
  border-bottom: 2px solid #3b82f6;
}

.tab-btn:hover:not(.active) {
  color: #d1d5db;
}

/* Coming soon tag */
.coming-soon-tag {
  font-size: 11px;
  background: rgba(245, 158, 11, 0.2);
  color: #f59e0b;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 6px;
  font-weight: 500;
  vertical-align: middle;
}

/* Coming soon overlay */
.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 10;
}

.coming-soon-overlay span {
  background: rgba(245, 158, 11, 0.8);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  transform: rotate(-10deg);
}

/* Batch modes */
.batch-modes {
  display: flex;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 6px;
  width: 100%;
}

.batch-mode-btn {
  flex: 1;
  background: transparent;
  border: none;
  color: #8a94a6;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.batch-mode-btn.active {
  background: rgba(59, 130, 246, 0.2);
  color: white;
}

.batch-mode-btn:hover:not(.active) {
  background: rgba(255, 255, 255, 0.05);
}

/* Form */
.stake-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-info {
  background: rgba(255, 255, 255, 0.05);
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
}

.fee-info {
  text-align: left;
}

.fee-info p {
  margin: 8px 0;
  font-size: 14px;
  color: #d1d5db;
}

.fee-info strong {
  color: #ffffff;
  font-weight: 600;
}

.fee-description {
  font-size: 13px;
  color: #8a94a6;
  margin-top: 12px;
  font-style: italic;
  width: 90%;
}

/* Input group */
.input-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
}

.amount-input {
  width: 100%;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: white;
  font-size: 18px;
  outline: none;
  box-sizing: border-box;
}

.amount-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px rgba(59, 130, 246, 0.5);
}

/* Button styles - common for all buttons */
button {
  border-radius: 12px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  padding: 12px 16px;
  font-size: 14px;
}

/* Action button */
.action-button {
  width: 100%;
  padding: 14px 16px;
  background-color: #3b82f6;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  margin-top: 8px;
}

.action-button:hover:not(:disabled) {
  background-color: #2563eb;
  transform: translateY(-1px);
}

.action-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #3b82f6;
}

/* Transaction status */
.transaction-status {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-radius: 12px;
  margin-top: 16px;
  gap: 12px;
}

.transaction-status.success {
  background-color: rgba(16, 185, 129, 0.1);
  border: 1px solid rgba(16, 185, 129, 0.2);
}

.transaction-status.pending {
  background-color: rgba(245, 158, 11, 0.1);
  border: 1px solid rgba(245, 158, 11, 0.2);
}

.transaction-status.error {
  background-color: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
}

.status-icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.status-message {
  flex: 1;
  text-align: left;
}

.status-message p {
  margin: 0 0 8px 0;
  font-weight: 500;
}

.transaction-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.transaction-links a {
  color: #3b82f6;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
}

.transaction-links a:hover {
  color: #2563eb;
  text-decoration: underline;
}

/* Error message */
.error-message {
  padding: 12px 16px;
  background-color: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  color: #ef4444;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  margin-top: 16px;
}

/* Connect prompt */
.connect-prompt {
  padding: 32px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  color: #8a94a6;
}

/* Account info */
.account-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.account-address {
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 12px;
  border-radius: 20px;
  font-family: monospace;
  font-size: 14px;
}

.connect-btn,
.disconnect-btn {
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.connect-btn {
  background: #3b82f6;
  color: white;
}

.connect-btn:hover {
  background: #2563eb;
}

.disconnect-btn {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  border: 1px solid rgba(239, 68, 68, 0.3);
}

.disconnect-btn:hover {
  background: rgba(239, 68, 68, 0.2);
}

/* Batches list */
.batches-list {
  margin-top: 24px;
  text-align: left;
}

.batches-list h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.batches-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.batches-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
}

.small-btn {
  padding: 6px 12px;
  border-radius: 6px;
  background: #3b82f6;
  color: white;
  border: none;
  font-size: 12px;
  cursor: pointer;
}

.small-btn:hover {
  background: #2563eb;
}

/* No batches message */
.no-batches {
  text-align: center;
  color: #8a94a6;
  padding: 20px;
  font-style: italic;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
}

/* Footer */
footer {
  padding: 32px;
  margin-top: 60px;
}

.powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
}

.powered-by span {
  color: #8a94a6;
  font-size: 14px;
}

.powered-by-link {
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.powered-by-link:hover {
  opacity: 0.8;
}

.ethena-logo,
.layerzero-logo {
  height: 24px;
  width: auto;
}

.powered-by-separator {
  color: #8a94a6;
}

/* Social Links with Icons */
.social-links {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.social-link {
  display: flex;
  align-items: center;
  color: #8a94a6;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 8px 12px;
}

.social-link:hover {
  color: #101111;
  background: rgba(255, 255, 255, 0.1);
}

.social-icon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.github-icon {
  filter: sepia(11%) saturate(300%) hue-rotate(175deg) brightness(91%)
    contrast(86%);
}

.x-icon {
  filter: sepia(11%) saturate(300%) hue-rotate(175deg) brightness(91%)
    contrast(86%);
}

.social-link:hover .github-icon,
.social-link:hover .x-icon {
  filter: sepia(11%) saturate(300%) hue-rotate(175deg) brightness(100%)
    contrast(86%);
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.social-links a {
  color: #8a94a6;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #d1d5db;
}

/* Responsive */
@media (max-width: 768px) {
  .App-logo {
    height: 120px;
  }

  .staking-container {
    padding: 20px;
  }

  .tabs {
    flex-direction: row; /* Keep tabs in a row */
  }

  .tab-btn {
    padding: 10px 8px;
    font-size: 14px;
  }

  .coming-soon-tag {
    font-size: 9px;
    padding: 1px 4px;
  }

  .batch-modes {
    padding: 4px;
  }

  .batch-mode-btn {
    padding: 6px 8px;
    font-size: 13px;
  }

  .amount-input,
  .action-button {
    padding: 12px;
    font-size: 16px;
  }

  .fee-info p {
    font-size: 13px;
  }

  button {
    padding: 10px 14px;
    font-size: 14px;
  }

  .action-button {
    padding: 12px 14px;
    font-size: 15px;
  }

  .amount-input {
    padding: 10px 14px;
    font-size: 16px;
  }

  .social-link {
    padding: 6px 12px;
    font-size: 13px;
  }

  .social-icon {
    height: 14px;
    width: 14px;
    margin-right: 6px;
  }
}

/* USD value */
.usd-value {
  font-size: 0.85em;
  opacity: 0.8;
  font-weight: 400;
  color: #a3e635;
}

/* Transaction Popup */
.tx-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(17, 25, 40, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  max-width: 320px;
  animation: slideIn 0.3s ease-out;
}

.tx-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.tx-popup-title {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.tx-popup-close {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 4px;
  font-size: 18px;
  line-height: 1;
}

.tx-popup-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tx-popup-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  background: rgba(59, 130, 246, 0.2);
  border-radius: 8px;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.2s ease;
  font-size: 14px;
}

.tx-popup-link:hover {
  background: rgba(59, 130, 246, 0.3);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive adjustments for the transaction popup */
@media (max-width: 768px) {
  .tx-popup {
    max-width: 280px;
    padding: 12px;
  }

  .tx-popup-link {
    padding: 8px 10px;
    font-size: 13px;
  }
}
